import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Tables.css";
import {DayMonth} from "../../../ADAPI";
import { ToastContainer, toast } from "react-toastify";
const DayAllowTab = (props) => { 
    const location = useLocation();
    const notify = () => toast(<>Delete Unavailable ! Contact Admin</>);
    const [oldTableData, newTableData] = useState([]);
    useEffect(()=>{
        axios.post(DayMonth, {aemail:location.state.emailid}).then(response => { if (response.data.Status === "fail") {
                    console.log("Failed")
        }else {
            newTableData(response.data);
        } });
    },[props]);

    return(<><table>
        <thead>
          <tr>
            <th>S No</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
            {oldTableData.map(data => { 
                return <tr>
                <td data-column="Sno">{data.sno}</td>
                <td data-column="Amount">{data.aprice}</td>
                <td data-column="Date">{data.adate}</td>
                <td data-column="Action"><button className="btn btn-danger" onClick={notify}>Delete</button></td>
              </tr>
             })}
          
        </tbody>
      </table>
      <ToastContainer  />
      </>);
}

export default DayAllowTab;