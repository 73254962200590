import axios from "axios";
import { useState, useEffect } from "react";
import  { GetCreds, AddingTask,OTaskReportMan,DelTaskApi } from "../../ADAPI";
const TaskMan = () => { 
    const [oldCredData, newCredData] = useState([]);
    const [oldUserName, newUsername] = useState();
    const [oldUserEmail, newUserEmail] = useState(); //UserName

    const [oldDataRepoAndTask, newDataRepoAndTask] = useState([]);
    const GettTableData = () =>{ axios.get(OTaskReportMan).then(response => {
        newDataRepoAndTask(response.data);
      }) }
    useEffect(GettTableData,[]);
    useEffect(()=>{axios.get(GetCreds).then(response => {
        if (response.data.Status === "fail") {
          
        }  else { 
              newCredData(response.data);
        }
      })},[])
    const [oldTaskValue, newTaskValue] = useState();
    const HandleTaskValChange = (event) => {    
        newTaskValue(event.target.value);
    }

    const HandleSubmitTask = (event) => { 
        event.preventDefault();
        if (Boolean(oldTaskValue) && Boolean(oldUserName) && Boolean(oldUserEmail)) {
            const SwapObj = {
                aname : oldUserName,
                aemail : oldUserEmail,
                atask : oldTaskValue
            }
            axios.post(AddingTask, SwapObj).then(response => { 
                console.log(response.data);
                event.target.reset();
                GettTableData();
            });
            
        }else { 
            alert("All Fields Required");
            event.target.reset();
        }
    }



    return(<div className="container">
    <h1>Add Task</h1>
    <h6><p><code>e.g 1.Task First <br />
                    &nbsp;&nbsp;&nbsp; 2. Task Second.<br />
                    &nbsp;&nbsp;&nbsp;
                    3. Task Third
        </code></p></h6>
    <form onSubmit={HandleSubmitTask}>
        <label>Select User Below</label>
    <select className="form-select" onChange={(event)=>{ const [a,b] = event.target.value.split(','); newUserEmail(a); newUsername(b)}}>
            <option></option>
            {oldCredData.map(data=>{
                
                return  <option value={[data.aemail, data.aname]}> {data.aname} </option>
                        
            })}
            </select>
            <p><code>
                <textarea className="form form-control" onChange={HandleTaskValChange}></textarea>
                </code></p>

                <button type="submit" className="btn btn-primary" >Add Task</button>
    </form>

    <table>
        <thead>
          <tr>
            <th>S No</th>
            <th>User Name</th>
            <th>Task</th>
            <th>Report</th>
            <th>Reporting Time</th>
            <th>Date of Allocation</th>
            <th>Action</th>

          </tr>
        </thead>
        <tbody>
            {oldDataRepoAndTask.map(data => { 
                return <tr>
                <td data-column="Sno">{data.sno}</td>
                <td data-column="User Name">{data.aname}</td>
                <td data-column="Task">{data.atask}</td>
                <td data-column="Report">{data.areport}</td>
                <td data-column="Task">{data.areportdate}</td>
                <td data-column="Task">{data.adate}</td>
                <td data-column="Action"><button className="btn btn-danger" onClick={() => { 
                    axios.post(DelTaskApi, { sno:data.sno }).then(response=> { GettTableData(); })
                }}>Delete</button></td>



              </tr>
             })}
          
        </tbody>
      </table>

    </div>);
}

export default TaskMan;