import axios from "axios";
import { useState } from "react";
import FileBase64 from 'react-file-base64';
import { useLocation } from "react-router-dom";
import { TravelUpAPI } from "../../../../ADAPI";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const TravUp = (props) => {
    const location = useLocation();
    const [oldForPercentage, newForPercentage] = useState(0);

    const [oldImgData, newImgData] = useState(); //For Storing Img Data
    const [oldShowImgUp, newShowImgUp] = useState(); // For Changing travelling State
    const [oldAmountInserted, newAmountInserted] = useState(); // For Amount Verification
    const [oldFrom, newFrom] = useState(); // Initial Position
    const [oldTo, newTo] = useState(); // Dest Location
    const [oldTransMode, newTransMode] = useState(); // For Storing Transport 
    const [oldAmount, newAmount] = useState();
    const [oldSubmitData, newOnSubmitData] = useState();

    const HandleTicketClick = () => { 
        newShowImgUp(<>
        {/* <FileBase64  multiple={false} onDone={(obj)=> { newImgData(obj.base64); }} /> */}
        <input type="file" accept=".png, .jpg, .jpeg" onChange={(event)=>{ newImgData(event.target.files[0]) }} name="aticket" />
        </>);
    }


    const HandleFormSubmit = (event) => { 
        event.preventDefault();


        if (oldTransMode === "Bus" || oldTransMode === "Train") {
            if(Boolean(oldAmount) && Boolean(oldFrom) && Boolean(oldTo) && Boolean(oldImgData)){
                // const TravObj = { 
                //     sno:props.sno,
                //     aamount:oldAmount,
                //     afrom:oldFrom,
                //     ato:oldTo,
                //     aby:oldTransMode,
                //     aticket:oldImgData
                
                // }
    
                const formdata = new FormData();
                formdata.append('sno', props.sno);
                formdata.append('aname', location.state.username);
                formdata.append('aamount', oldAmount);
                formdata.append('afrom', oldFrom);
                formdata.append('ato', oldTo);
                formdata.append('aby', oldTransMode);
                formdata.append('aticket', oldImgData);
    
                axios.post(TravelUpAPI, formdata, {headers: {
                    'content-type': 'multipart/form-data'
                }, onUploadProgress: (progressSwap) => { newForPercentage(Math.floor( (progressSwap.loaded*100)/progressSwap.total )) }}).then(response => { 
                    newOnSubmitData("Updated");
                    window.location.reload();
                    console.log(response.data);
                });
            }
            else { 
                newOnSubmitData("All Fields Required");
            }
        }else { 
            if(Boolean(oldAmount) && Boolean(oldFrom) && Boolean(oldTo)){
                // const TravObj = { 
                //     sno:props.sno,
                //     aamount:oldAmount,
                //     afrom:oldFrom,
                //     ato:oldTo,
                //     aby:oldTransMode,
                //     aticket:oldImgData
                
                // }
    
                const formdata = new FormData();
                formdata.append('sno', props.sno);
                formdata.append('aamount', oldAmount);
                formdata.append('afrom', oldFrom);
                formdata.append('ato', oldTo);
                formdata.append('aby', oldTransMode);
                formdata.append('aticket', oldImgData);
    
                axios.post(TravelUpAPI, formdata,{headers: {
                    'content-type': 'multipart/form-data'
                }, onUploadProgress: (progressSwap) => { newForPercentage(Math.floor( (progressSwap.loaded*100)/progressSwap.total )) }}).then(response => { 
                    newOnSubmitData("Updated");
                    window.location.reload();
                    console.log(response.data);
                });
            }
            else { 
                newOnSubmitData("All Fields Required");
            }
        }

    }
    // const sno = props.sno;
    return(<><form onSubmit={HandleFormSubmit}>
        <label>From : Initial Place</label>
        <input className="form form-control" type="text" placeholder="From" onChange={(event)=>{
            newFrom(event.target.value);
        }} />

        <label>To : Destination</label>
        <input className="form form-control" type="text" placeholder="To" onChange={(event)=>{ newTo(event.target.value); }} />

        <label>Travelling Type</label><br />
        <input className="form-check-input" type="radio" name="ss" onClick={()=>{HandleTicketClick();newTransMode("Bus")}} /> Bus
        <input className="form-check-input" type="radio" name="ss" onClick={()=>{HandleTicketClick();newTransMode("Train")}} /> Train
        
        <input className="form-check-input" type="radio" name="ss" onClick={()=>{  newShowImgUp(<>
            <input type="text" className="form-control" placeholder="Travalling Vehicle" onChange={(event)=>{
                newTransMode(event.target.value);
            }} />
        </>) }}/>Other
        
        {oldShowImgUp}

        {/* <img src={oldImgData} alt="Upload Image Ticket Available" height="50px" width="50px" /> */}
        <br />
        <label>Amount</label>
        <input className="form form-control" type="text" placeholder="Amount" onChange={(event)=>{ if(isNaN(event.target.value)){ newAmountInserted(<h6 style={{"color":"red"}}>"Please Enter Valid Value"</h6>); } else
        { newAmountInserted(<>INR : {event.target.value} ₹ </>); newAmount(event.target.value); }
    }}/> {oldAmountInserted}
        <br />
        {oldSubmitData}
        <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar value={oldForPercentage} text={`${oldForPercentage}%`} />;
            </div>
        <button type="submit" className="btn btn-primary">Submit</button>


    </form></>);
}

export default TravUp;