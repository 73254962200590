import axios from "axios";
import { useEffect, useState } from "react";
import { GetCreds } from "./ADAPI.js";
import { useNavigate } from "react-router-dom";
import "./AdminLog.css";
import logoad from "./Users/Components/logo.png";
import { getAuth, signInWithRedirect,getRedirectResult,GoogleAuthProvider } from "firebase/auth";
import { app } from "./FireBase"
const AdminLog = () => { 

    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    const [oldFlag, newFlag] = useState(false); // For Email Input Store
    const [oldCredData, newCredData] = useState([]); // For Admin Reserve Mails
    const [googleEmail, newgoogleEmail] = useState();
    const [googleImg, newgoogleImg] = useState();

    const navigate = useNavigate(); // Handle Navigation
    


    useEffect(()=>{
        axios.get(GetCreds).then(response => { newCredData(response.data); });   
    }, []);
    // const HandleLoginEvent =(imagedata, emailid) => { 
    //     // For Handling Email Submit
        
    //     oldCredData.map(data => {
    //         if (data.aemail === emailid && data.atype === "admin") {
    //             navigate("adminpage");
    //         }
    //         if (data.aemail === emailid && data.atype === "user") {
    //             navigate("userpage", { replace:true, state:{ username:data.aname, emailid:data.aemail } });
    //         }
    //         return 0;
    //     })
            

    // }

    const HandleSubmit =() =>{
        signInWithRedirect(auth, provider);
        
    }
    getRedirectResult(auth)
        .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
    
        // The signed-in user info.
        const user = result.user;
        console.log(user);
        newgoogleEmail(user.email);
        newgoogleImg(user.photoURL);
        newFlag(true);
        
        
        }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
    });
    if (oldFlag) {
        oldCredData.map(data => {
            if (data.aemail === googleEmail  && data.atype === "admin") {
                navigate("adminpage");
            }
            if (data.aemail === googleEmail && data.atype === "user") {
                navigate("userpage", { replace:true, state:{ username:data.aname, emailid:data.aemail, imgdata: googleImg } });
            }
            if (data.aemail === googleEmail && data.atype === "subadmin") {
                navigate("subadmin", { replace:true, state:{ username:data.aname, emailid:data.aemail, imgdata: googleImg } });
            }
            // accpage
            if (data.aemail === googleEmail && data.atype === "acc") {
                navigate("accpage", { replace:true, state:{ username:data.aname, emailid:data.aemail, imgdata: googleImg } });
            }
            return 0;
        })
    }
    return(<div className="" style={{}}>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

<img src={logoad} className="Swapcontainer container" alt="Logo" style={{"height":"150px","width":"150px"}} />
<br/>
<hr />
        <button className="Swapcontainer container google btn" onClick={HandleSubmit}>
          <i className="fa fa-google"></i>&nbsp;Login with Google
        </button>
    {/* <form onSubmit={HandleSubmit}>
        <input className="form-control" type="text" onChange={HandleChangeEmail} placeholder="Enter Your Email ID" />
        <button className="btn btn-primary" type="submit">Login</button>
    </form> */}
    </div>);
}

export default AdminLog;