import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Tables.css";
import {TravelMonth,serverUrl} from "../../../ADAPI";
// Toast Liabrary
import { ToastContainer, toast } from 'react-toastify';
import TravUp from "./Update/TravUp";
const TravelTab = (props) => { 
    const location = useLocation();
    const [oldTableData, newTableData] = useState([]);
    // For Showing Toast
    const notify = (sno) => toast(<TravUp sno={sno} />);
    useEffect(()=>{
        axios.post(TravelMonth, {aemail:location.state.emailid}).then(response => { 

                if (response.data.Status === "fail") {
                    
                }else {
                    newTableData(response.data);
                }
         });
    },[props]);

    

    return(<><table>
        <thead>
          <tr>
            <th>S No</th>
            <th>From</th>
            <th>To</th>
            <th>By</th>
            <th>Ticket</th>
            <th>Price</th>
            <th>Date</th>
            <th>Action</th>

          </tr>
        </thead>
        <tbody>
            {
            oldTableData.map(data => { 
                return <tr>
                <td data-column="Sno">{data.sno}</td>
                <td data-column="From">{data.afrom}</td>
                <td data-column="To">{data.ato}</td>
                <td data-column="By">{data.aby}</td>
                <td data-column="Ticket"><img src={serverUrl+"/adapi/postapi/uploads/travel/"+data.aticket} alt="NA" height="50px" width="50px" /></td>
                <td data-column="Price">{data.aprice}</td>
                <td data-column="Date">{data.adate}</td>

                <td data-column="Action"><button onClick={()=>{notify(data.sno)}} className="btn btn-info">Update</button></td>
              </tr>
             })
             }
          
        </tbody>
      </table>
      {/* <button onClick={()=>{notify(3)}}>Update</button> */}
      {/* Toast Postition */}
      {/* Toast Position */}
      <ToastContainer autoClose={false} closeOnClick={false} />
      </>);
}

export default TravelTab;