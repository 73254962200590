// export const serverUrl = "http://localhost/";
export const serverUrl = "https://exp.adigrp.com/";
// Get Users And Admins Data API

export const GetDateOfAllowance = serverUrl+"adapi/getapi/CheckDayAllow.php";

export const GetCreds = serverUrl+"adapi/getapi/usercred.php";

// Create New User

export const UserCreateAPI = serverUrl+"adapi/postapi/Users.php";

// Del User
export const UserDelAPI = serverUrl+"adapi/postapi/UserDel.php";


// All Expa Managment
export const DebApi = serverUrl+"adapi/postapi/DebitAmount.php";

// creditAmount.php Amount 

export const CreditPostAPI = serverUrl+"adapi/postapi/creditAmount.php";

// POST Travel Data
export const PostTravelApi = serverUrl+"adapi/postapi/TravllelExp.php";


// POST Hotel or Logg Data
export const PostLoggHotel = serverUrl+"adapi/postapi/LoggHotel.php";


//POST Goods Details Data

export const GoodDataUp = serverUrl+"adapi/postapi/GoodsPur.php";

// Daily Allowance API Data

export const DailyDataAPI = serverUrl+"adapi/postapi/DayAllo.php";


// Get API For Credits Month

export const CredMonth = serverUrl+"adapi/getapi/CredFetchMonth.php";

// Get API For Debits Month

export const DebMonth = serverUrl+"adapi/getapi/DebFetchMonth.php";

// Get API For DayLyAllow

export const DayMonth  = serverUrl+"adapi/getapi/DayAllowMonth.php";

// Get API for Goods 

export const GoodsMonth = serverUrl+"adapi/getapi/GoodsFetchMonth.php";

// Get API for Hotels 

export const HotelMonth = serverUrl+"adapi/getapi/LogFetchMonth.php";

// Get API for Travels 

export const TravelMonth = serverUrl+"adapi/getapi/TravelFetchMonth.php";

// Get Data of Cast by User
export const CreditsMoney = serverUrl+"adapi/getcast/Creds.php";
export const TotalCredMoney =serverUrl+"adapi/getcast/TotalCredits.php";
export const TravelMoney = serverUrl+"adapi/getcast/Travel.php";
export const HotelMoney = serverUrl+"adapi/getcast/Hotel.php";
export const GoodsMoney = serverUrl+"adapi/getcast/Goods.php";
export const AllowMoney = serverUrl+"adapi/getcast/DayAllow.php";
export const ReturnCreds = serverUrl+"adapi/getcast/ReturnCredsFetch.php";

// Get API Overall Year
export const OTravelMoney = serverUrl+"adapi/getcast/OTravel.php";
export const OHotelMoney = serverUrl+"adapi/getcast/OHotel.php";
export const OGoodsMoney = serverUrl+"adapi/getcast/OGoods.php";
export const OAllowMoney = serverUrl+"adapi/getcast/ODayAllow.php";
// New One Added
export const OReturnCreds = serverUrl+"adapi/getcast/OReturnCredsFetch.php";
// Update API 

export const GoodsUpAPI = serverUrl+"adapi/upapi/Goods.php";
export const TravelUpAPI = serverUrl+"adapi/upapi/Travel.php";
export const HotelUpAPI = serverUrl+"adapi/upapi/Hotel.php";


// Return Amount Add API POST 

export const ReturnCredsAddAPI = serverUrl+"adapi/postapi/ReturnCred.php";

// Task And Report Management API's

export const OTaskReportMan = serverUrl+"adapi/TaskAndRepo/GetTaskRepo.php"; 

export const TaskReportMan = serverUrl+"adapi/TaskAndRepo/GetTaskRepoUser.php";

export const UserTaskRepo = serverUrl+"adapi/TaskAndRepo/GetTodaysTask.php";

export const AddingTask = serverUrl+"adapi/TaskAndRepo/AddingTaskApi.php";

export const AddingReport = serverUrl+"adapi/TaskAndRepo/AddingReportApi.php";
export const DelTaskApi= serverUrl+"adapi/TaskAndRepo/DelTaskApi.php"
// export CSV File

export const OCSVFileExp = serverUrl+"adapi/exportcsv/FinalCSV.php";


// 06-09-2022 GetReturnCred API

export const OGetReturnCredits = serverUrl+"adapi/CredAndDebApi/GetReturnCreds.php";

export const ODelReturnCredis = serverUrl+"adapi/CredAndDebApi/DelReturnCreds.php";

// Credits Table Api

export const FetchUsersCredsByName = serverUrl+"adapi/CredAndDebApi/GetCreditsData.php";

export const DelCredData = serverUrl+"adapi/CredAndDebApi/DelCredData.php";



// DailyReportApis


export const DailyReportGetter = serverUrl+"adapi/CredAndDebApi/DailyReportGetter.php";
export const DailyReportPoster = serverUrl+"adapi/CredAndDebApi/DailyReportPost.php";
export const DailyReportUpdater = serverUrl+"adapi/CredAndDebApi/DailyReportUpdate.php";




// Task Completion Ratio API


export const OTasks = serverUrl+"adapi/Taskgraph/OTaskCount.php";
export const OComps = serverUrl+"adapi/Taskgraph/OTaskCom.php";
export const MComps = serverUrl+"adapi/Taskgraph/MTaskCom.php";
export const MTasks = serverUrl+"adapi/Taskgraph/MTaskCount.php";



export const POSTATTENDANCE = serverUrl+"adapi/att/postAtt.php";


export const GetCredDateSwap = serverUrl+"adapi/getapi/GetTwoDayCreds.php";


// CSV Genarate Calculation As Per Month

export const CreditsMoneyNew = serverUrl+"adapi/csvmonthcalc/Creds.php";
export const TravelMoneyNew = serverUrl+"adapi/csvmonthcalc/Travel.php";
export const HotelMoneyNew = serverUrl+"adapi/csvmonthcalc/Hotel.php";
export const GoodsMoneyNew = serverUrl+"adapi/csvmonthcalc/Goods.php";
export const AllowMoneyNew = serverUrl+"adapi/csvmonthcalc/DayAllow.php";
export const ReturnCredsNew = serverUrl+"adapi/csvmonthcalc/ReturnCredsFetch.php";