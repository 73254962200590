import axios from "axios";
import { useState }  from "react";
import FileBase64 from 'react-file-base64';
import { useLocation } from "react-router-dom";
import { HotelUpAPI } from "../../../../ADAPI";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const HotelUp = (props) => { 
    const location = useLocation();
    const [oldForPercentage, newForPercentage] = useState(0);

    const [oldAmountInserted, newAmountInserted] = useState(); // For Amount Verification 
    const [oldAmount, newAmount] = useState(); // Amount Store
    // For Hotel Name
    const [oldHotelName, newHotelName] = useState();
    // For Place Name
    const [oldPlace, newPlace] = useState();
    // For Image Storing
    const [oldImgData, newImageData]= useState(); 
    
    // For Showing Message
    const [oldShowMess,newShowMess] = useState();

    const sno = props.sno;

    const HandleSubmitData = (event) => { 
        event.preventDefault();
        if(Boolean(oldAmount) && Boolean(oldHotelName) && Boolean(oldPlace) && Boolean(oldImgData)) {
            // const HotelObj = {
            //     sno:props.sno,
            //     aamount : oldAmount,
            //     ahotel : oldHotelName,
            //     aplace : oldPlace,
            //     abill : oldImgData
            // }
            const formdata = new FormData();
            formdata.append('sno',props.sno);
            formdata.append('aname', location.state.username);
            formdata.append('aamount', oldAmount);
            formdata.append('ahotel', oldHotelName);
            formdata.append('aplace', oldPlace);
            formdata.append('abill', oldImgData);
            
            axios.post(HotelUpAPI, formdata, {headers: {
                'content-type': 'multipart/form-data'
            }, onUploadProgress: (progressSwap) => { newForPercentage(Math.floor( (progressSwap.loaded*100)/progressSwap.total )) }}).then(response => { 
                console.log(response);
                newShowMess("Updated");
                window.location.reload();
            })

        } else {
            newShowMess("All Fields Required");
        }
    }

    return(<>
        <form onSubmit={HandleSubmitData} encType="multipart/form-data">
            <label>Enter A Hotel Name</label>
            <input onChange={(event)=>{ newHotelName(event.target.value); }} type="text" className="form form-control" />
            <label>Enter A Place</label>
            <input onChange={(event)=>{ newPlace(event.target.value) }} type="text" className="form form-control" />
            <label>Enter A Price</label>
            <input className="form form-control" type="text" placeholder="Amount" onChange={(event)=>{ if(isNaN(event.target.value)){ newAmountInserted(<h6 style={{"color":"red"}}>"Please Enter Valid Value"</h6>); } else
            { newAmountInserted(<>INR : {event.target.value} ₹ </>); newAmount(event.target.value); }
            }}/> {oldAmountInserted}
            {/* <FileBase64 onDone={ (obj)=>{ newImageData(obj.base64) } } /> */}
            <input type="file" accept=".png, .jpg, .jpeg" onChange={(event)=>{ newImageData(event.target.files[0]) }} name="abill" />
            <br />
            {oldShowMess}
            <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar value={oldForPercentage} text={`${oldForPercentage}%`} />;
            </div>
            <button type="submit" className="btn btn-primary">Update</button> 
            
        </form>
    </>);
}

export default HotelUp;