import axios from "axios";
import { useEffect, useState } from "react";
import { GetCreds,OCSVFileExp,CreditPostAPI, FetchUsersCredsByName, DelCredData, GetCredDateSwap } from "../../ADAPI";

// API For Fetching Calculations

import { CreditsMoneyNew, TotalCredMoney, TravelMoneyNew, HotelMoneyNew, GoodsMoneyNew, AllowMoneyNew,OTravelMoney, OHotelMoney, OGoodsMoney, OAllowMoney, ReturnCredsNew, OReturnCreds } from "../../ADAPI";

// import {DatePicker} from "react-datepicker";
import DatePicker from 'react-date-picker';
import TwoDays from "../../Users/Components/DataTables/TwoDay";
const CreditsAdd = () => { 

// Credit Date Selector
const [oldCredDateSwap, newCredDateSwap] = useState();
const SwapDateObj = new Date(oldCredDateSwap);

// console.log(SwapDateObj.toISOString().slice(0, 19).replace('T', ' '));


    const [oldMail, newMail] = useState(); //For CSV File


    const [oldTotal, newTotal] = useState(0); // Total Credits
    const [oldmonthcred, newmonthcred] = useState(0); // Current Month Credits
    const [oldTrav, newTrav] = useState(0); // Cuurent Travalling Debs
    const [oldGoods, newGoods] = useState(0); // Current
    const [oldHotel, newHotel] = useState(0); // Current
    const [oldAllow, newAllow] = useState(0); // Current
    const [oldReturnCreds, newReturnCreds] = useState(0);
// Overall Year Calcs
    const [oldOTrav, newOTrav] = useState(0); // Cuurent Travalling Debs
    const [oldOGoods, newOGoods] = useState(0); // Current
    const [oldOHotel, newOHotel] = useState(0); // Current
    const [oldOAllow, newOAllow] = useState(0); // Current
    const [oldOReturnCreds, newOReturnCreds] = useState(0);
// Calender
const [oldCalenderValfrom, newCalenderValfrom] = useState();
const d = new Date(oldCalenderValfrom);
const Datefrom = d.getDate();
const Monthfrom = d.getMonth()+1;
const Yearfrom = d.getFullYear();

// TO Date
const [oldCalenderValto, newCalenderValto] = useState();
const dto = new Date(oldCalenderValto);
const Dateto = dto.getDate();
const Monthto = dto.getMonth()+1;
const Yearto = dto.getFullYear();

console.log(dto);



    const CalAll = () => { 
      axios.post(TotalCredMoney, { aemail : oldMail }).then(response =>{
          response.data.map(data=>{ if (!isNaN(data.asum)) {
            newTotal(data.asum); 
          }
          })
      });
      axios.post(CreditsMoneyNew, { aemail : oldMail, fromdate : oldCalenderValfrom, todate : oldCalenderValto }).then(response => {
        response.data.map(data=>{  if (data.asum === null) {} else {newmonthcred(data.asum)}      }); 
      });
      axios.post(TravelMoneyNew, { aemail : oldMail, fromdate : oldCalenderValfrom, todate : oldCalenderValto  }).then(response => {
        response.data.map(data=>{ if (data.asum === null) {} else { newTrav(data.asum)} }); 
      });
      axios.post(GoodsMoneyNew, { aemail : oldMail, fromdate : oldCalenderValfrom, todate : oldCalenderValto  }).then(response => {
        response.data.map(data=>{ if (data.asum === null) {}else{newGoods(data.asum)} }); 
      });
      axios.post(HotelMoneyNew, { aemail : oldMail, fromdate : oldCalenderValfrom, todate : oldCalenderValto  }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { }else{newHotel(data.asum)} }); 
      });
      axios.post(AllowMoneyNew, { aemail : oldMail, fromdate : oldCalenderValfrom, todate : oldCalenderValto  }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { } else{ newAllow(data.asum)} }); 
      });

      axios.post(ReturnCredsNew, { aemail : oldMail, fromdate : oldCalenderValfrom, todate : oldCalenderValto  }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { } else{ newReturnCreds(data.asum)} }); 
      });


      axios.post(OTravelMoney, { aemail : oldMail }).then(response => {
        response.data.map(data=>{ if (data.asum === null) {} else { newOTrav(data.asum)} }); 
      });
      axios.post(OGoodsMoney, { aemail : oldMail }).then(response => {
        response.data.map(data=>{ if (data.asum === null) {}else{newOGoods(data.asum)} }); 
      });
      axios.post(OHotelMoney, { aemail : oldMail }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { }else{newOHotel(data.asum)} }); 
      });
      axios.post(OAllowMoney, { aemail : oldMail }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { } else{ newOAllow(data.asum)} }); 
      });
      axios.post(OReturnCreds, { aemail : oldMail }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { } else{ newOReturnCreds(data.asum)} }); 
      });
      
    }
    useEffect(CalAll,[oldMail, oldCalenderValto, oldCalenderValfrom]);
    console.log(oldAllow);
    console.log(oldGoods);
    console.log(oldHotel);
    console.log(oldTrav);
    
    

    const Swap = parseFloat(oldAllow)+parseFloat(oldGoods)+parseFloat(oldHotel)+parseFloat(oldTrav); // Total Monthly uses
    
    
    const Swap2 = parseFloat(oldOAllow)+parseFloat(oldOGoods)+parseFloat(oldOHotel)+parseFloat(oldOTrav); 
    // Yearly Uses
    const YearlyRemainingCreds =  oldTotal - Swap2 - oldOReturnCreds;


    const MonthRemainingCreds = oldmonthcred - Swap - oldReturnCreds;




    console.log(YearlyRemainingCreds+" "+MonthRemainingCreds);






















    // const [oldMonth, newMonth] = useState(8);
    // const [oldYear, newYear] = useState(2022);
    const [oldUserName, newUsername] = useState();
    const [oldUserEmail, newUserEmail] = useState(); //UserName
    const [showMess, newShowMess] = useState();
    
    const [oldName, newName] = useState(); //For CSV
    const [oldAmount, newAmount] = useState();
    const [oldCredData, newCredData] = useState([]);
    const [oldInvalidInput, newInvalidInput] = useState();
    useEffect(()=>{axios.get(GetCreds).then(response => {
      if (response.data.Status === "fail") {
        
      }  else { 
            newCredData(response.data);
      }
    })},[])
    const HandleSubmit = (event) => { 
        event.preventDefault();
        if(Boolean(oldUserEmail) && Boolean(oldUserName) && Boolean(oldAmount) && Boolean(oldCredDateSwap)) {
            const SwapObj = { 
                aname:oldUserName,
                aemail:oldUserEmail,
                aamount:oldAmount,
                adate: SwapDateObj.toISOString().slice(0, 19).replace('T', ' ')
            }
            axios.post(CreditPostAPI, SwapObj).then(response => { 
                console.log(response.data);
                if(response.data.Status === "done"){
                    newShowMess(<>Credited {oldAmount+"₹"}</>);
                    newAmount("");
                    event.target.reset();
                    TableCredDataGetter();
                }
            })
        }else{
          newShowMess(<>All Fields Required</>);
        }
    }



    // Calculations Below
    // Calculations Above

    const SendParString =   `Datefrom=${Datefrom}&Monthfrom=${Monthfrom}&Yearfrom=${Yearfrom}&Dateto=${Dateto}&Monthto=${Monthto}&Yearto=${Yearto}&e=${oldMail}&n=${oldName}&totalrc=${YearlyRemainingCreds}&monthcred=${oldmonthcred}&monthuse=${Swap}&totalmc=${MonthRemainingCreds}`;

    const [oldShowSwapbtn, newShowSwapbtn] = useState({"display":"none"});




// Table Code For Credis

const [oldCreditsAmount, newCreditsAmount] = useState([]); // variable For Table Data
const TableCredDataGetter = () => { 
  axios.post(FetchUsersCredsByName, { aemail : oldUserEmail }).then(
    response => { 
      newCreditsAmount(response.data);
    }
  )
}

useEffect(TableCredDataGetter, [oldUserEmail]);

// console.log(oldCreditsAmount);


    return(<div className="container"> <h3>Admin Panel</h3>
    
   
    
    <form style={{ "paddingTop":"50px" }} onSubmit={HandleSubmit}>
            <input className="form form-control" type="text" onChange={(event)=>{
                if (isNaN(event.target.value)) {
                    newInvalidInput(<div style={{"color":"red"}}>"Invalid Input"</div>);
                }else{
                    newInvalidInput(<div>{event.target.value} ₹</div>);
                    newAmount(event.target.value)}
            }} placeholder="Enter Amount" />{oldInvalidInput}
            
            {/* Data Selecter Below */}
            <br />
            <input className="form form-control" onChange={(e)=>{ newCredDateSwap(e.target.value); }} type="datetime-local" />
            <br />
            
            <select className="form-select" onChange={(event)=>{ const [a,b] = event.target.value.split(','); newUserEmail(a); newUsername(b)}}>
            <option></option>
            {oldCredData.map(data=>{
                
                return  <option value={[data.aemail, data.aname]}> {data.aname} </option>
                        
            })}
           


            </select>

            {showMess}



            <button type="submit" className="btn btn-primary">Submit</button>
        </form> 
        <TwoDays flag="false" />
    
            <br />

{/* Credits Adding Table */}
<table>
        <thead>
          <tr>
            <th>S No</th>
            <th>Credited To</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
{
   oldCreditsAmount.map(data => {
    return <tr>
    <td data-column="Sno">{data.sno}</td>
    <td data-column="Credited To">{data.aname}</td>
    <td data-column="Amount">{data.acred}</td>
    <td data-column="Date">{data.adate}</td>
    <td data-column="Action"><button className="btn btn-danger" onClick={ ()=>{
        axios.post(DelCredData, { sno : data.sno }).then(
          response => { TableCredDataGetter() }
        )
    }}>Delete</button></td>
  </tr>
   }).reverse()
}
</tbody>
      </table>


{/* CSV Generater Below */}





            <hr />

            <h1>Export Expanses As CSV</h1>
            <select className="form-select" onChange={(event)=>{const [a,b] = event.target.value.split(',');
            newMail(a);
            newName(b);
            newShowSwapbtn({});
        }}>
            <option></option>
            {oldCredData.map(data=>{
                
                return  <option value={[data.aemail, data.aname]}> {data.aname} </option>
                        
            })}
           


            </select>
            <label>From</label>
            <DatePicker onChange={newCalenderValfrom}  value={oldCalenderValfrom} />
            <br />
            <label>To</label>
            <DatePicker onChange={newCalenderValto}  value={oldCalenderValto} />


            {/* <input type="text" className="form form-control" onChange={(event)=>{newMonth(event.target.value);}} placeholder="Enter Month as 00 or 06 or 12" />
            <input type="text" className="form form-control" onChange={(event)=>{newYear(event.target.value);}} placeholder="Enter Year e.g. 2013, 2022" /> */}

      <a href={OCSVFileExp+"?"+SendParString} style={oldShowSwapbtn} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Export</a>

        <button className="btn btn-info" onClick={()=>{ window.location.reload() }}>Refresh</button>
        </div>);
}
export default CreditsAdd;