import axios from "axios";
import { useState, useEffect } from "react";
import {GetCreds,ReturnCredsAddAPI} from "../../ADAPI";
import ReturnCredsTab from "./ReturnCredsTab";
const ReturnAmount = () => { 
    // State Vars 
    const [oldRAmount, newRAmount] = useState();
    const [oldCredData, newCredData] = useState([]);
    const [oldUserName, newUsername] = useState();
    const [oldUserEmail, newUserEmail] = useState(); //UserName
    const [oldSwapMess, newSwapMess] = useState();
    useEffect(()=>{axios.get(GetCreds).then(response => {
        if (response.data.Status === "fail") {
          
        }  else { 
              newCredData(response.data);
        }
      })},[]);


      const HandleSubmit = (event) => { 
        event.preventDefault();
        if (Boolean(oldUserName) && Boolean(oldUserEmail) && Boolean(oldRAmount)) {
            const SwapObj = { 
                aname : oldUserName,
                aemail : oldUserEmail,
                aamount : oldRAmount
            }
            axios.post(ReturnCredsAddAPI, SwapObj).then(response => {console.log(response);
            if (response.data.Status === "done") {
                newSwapMess("Amount Returned Rs "+oldRAmount);
                newRAmount();
                newUsername();
                newUserEmail();
                window.location.reload();
            }else { 
                newSwapMess("Something Went Wrong");
            }
            });
        }
        else { 
            newSwapMess("All Fields Required");
        }
      }
    return(<div className="container">
        <br />
        <br />
        <form onSubmit={HandleSubmit}>
            <input type="text" className="form form-control" placeholder="Enter Amount That Return" onChange={(event) => { 
                newRAmount(event.target.value);
            }} />
            <select className="form-select" onChange={(event)=>{ const [a,b] = event.target.value.split(','); newUserEmail(a); newUsername(b)}}>
            <option></option>
            {oldCredData.map(data=>{
                
                return  <option value={[data.aemail, data.aname]}> {data.aname} </option>
                        
            })}
           


            </select>
            {oldSwapMess}
            <button type="submit" className="btn btn-primary">Submit</button>
        </form>
        <ReturnCredsTab />
    </div>);
}

export default ReturnAmount;