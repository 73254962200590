import axios from "axios";
import { useState } from "react";
import FileBase64 from 'react-file-base64';
import { useLocation } from "react-router-dom";
import { GoodsUpAPI } from "../../../../ADAPI";
import GoodsTab from "../GoodsTab";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const GoodsUp = (props) => {
    const location = useLocation();
    const [oldForPercentage, newForPercentage] = useState(0);

    const [oldImgData, newImgData] = useState(); //For Storing Img Data
    const [oldMatname, newMatname] = useState(); // For Storing Material Name
    const [oldFrom, newFrom] = useState();
    const [oldAmountInserted, newAmountInserted] = useState(); // For Amount Verification 
    const [oldAmount, newAmount] = useState(); // Amount Store
    const [oldSubmitData, newOnSubmitData] = useState();
    const [oldTab, newTab] = useState();

    const HandleSubmitGood = (event) => { 
        event.preventDefault();
        if (Boolean(oldMatname) && Boolean(oldFrom) && Boolean(oldAmount) && Boolean(oldImgData)) {
            // const Goodobj = {
            //     sno:props.sno,
            //     aamount:oldAmount,
            //     amatename:oldMatname,
            //     afrom:oldFrom,
            //     abill:oldImgData
            // }
            const formdata = new FormData();
            formdata.append('sno', props.sno);
            formdata.append('aname', location.state.username);
            formdata.append('aamount',oldAmount);
            formdata.append('amatename',oldMatname);
            formdata.append('afrom',oldFrom);
            formdata.append('abill',oldImgData);

            axios.post(GoodsUpAPI, formdata,{headers: {
                'content-type': 'multipart/form-data'
            }, onUploadProgress: (progressSwap) => { newForPercentage(Math.floor( (progressSwap.loaded*100)/progressSwap.total )) }}).then(response => { 
                console.log(response);
                newOnSubmitData(<div style={{"color":"green"}}>Updated</div>);
                window.location.reload(false);
              })

            event.target.reset();


        }
    }
    
    return(<>        <form onSubmit={HandleSubmitGood} encType="multipart/form-data">
            <label>Material Name</label>
            <input className="form form-control" onChange={(event)=>{newMatname(event.target.value);}} type="text" placeholder="Material Name" />
            <label>From</label>
            <input className="form form-control" onChange={(event)=>{ newFrom(event.target.value); }} type="text" placeholder="From : Shop Name" />
            <label>Amount</label>
            
            <input className="form form-control" type="text" placeholder="Amount" onChange={(event)=>{ if(isNaN(event.target.value)){ newAmountInserted(<h6 style={{"color":"red"}}>"Please Enter Valid Value"</h6>); } else
            { newAmountInserted(<>INR : {event.target.value} ₹ </>); newAmount(event.target.value); }
            }}/> {oldAmountInserted}

            <label>Bill Reciept</label>
            <input type="file" accept=".png, .jpg, .jpeg" onChange={(event)=>{ newImgData(event.target.files[0]) }} name="abill" />
            {/* <FileBase64 multiple={false} onDone={(obj)=> { newImgData(obj.base64); }} /> */}
            {/* <img src={oldImgData} alt="Upload  Ticket Available" height="50px" width="50px" /><br /> */}
            {oldSubmitData}
            <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar value={oldForPercentage} text={`${oldForPercentage}%`} />;
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
            <button type="reset" className="btn btn-danger">Clear</button>

        </form></>)
}

export default GoodsUp;