import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Tables.css";
import {HotelMonth, serverUrl} from "../../../ADAPI";
import { ToastContainer, toast } from "react-toastify";
import HotelUp from "./Update/HotelUp";

const HotelTab = (props) => { 
    const location = useLocation();
    const [oldTableData, newTableData] = useState([]);
    const notify = (sno) => { toast(<HotelUp sno={sno} />); }
    useEffect(()=>{
        axios.post(HotelMonth, {aemail:location.state.emailid}).then(response => { if (response.data.Status === "fail") {
                    
        }else {
            newTableData(response.data);
        } });
    },[props]);
    return(<>
    <table>
        <thead>
          <tr>
            <th>S No</th>
            <th>Hotel Name</th>
            <th>Place</th>
            <th>Amount</th>
            <th>Reciept</th>
            <th>Date</th>
            <th>Action</th>

          </tr>
        </thead>
        <tbody>
            {oldTableData.map(data => { 
                return <tr>
                <td data-column="Sno">{data.sno}</td>
                <td data-column="Hotel Name">{data.ahotel}</td>
                <td data-column="Place">{data.aplace}</td>
                <td data-column="Amount">{data.aprice}</td>
                <td data-column="Reciept"><img src={serverUrl+"/adapi/postapi/uploads/hotels/"+data.abill} alt="NA" height="100px" width="100px" /></td>
                <td data-column="Date">{data.adate}</td>

                <td data-column="Action"><button onClick={()=>{notify(data.sno)}} className="btn btn-info">Update</button></td>
              </tr>
             })}
          
        </tbody>
      </table>
      <ToastContainer autoClose={false} closeOnClick={false} />
    </>);
}

export default HotelTab;