import { useEffect, useState } from "react";
import DayAllow from "./DayAllow";
import { GetDateOfAllowance } from "../../ADAPI"; 
import axios from "axios";
import { useLocation } from "react-router-dom";

const DayAllowShow = () => { 
    const location = useLocation();
    const d = new Date();
    // Network Correction

    const DateToday = d.getDate();

    let flag = false;

    const [oldShowBody, newShowBody] = useState(<>Loading...</>);
    const [oldDateData, newDateData] = useState([]);
    const GetAllowanceDates = () => { 
        axios.post(GetDateOfAllowance, { aemail: location.state.emailid }).then(
            response => { 
                newDateData(response.data);
                newShowBody(<DayAllow />);
            }
        )
    }
    useEffect(GetAllowanceDates, []);

// For Checking Ststus


     oldDateData.map(data => {
        //  console.log(new Date(data.adate));
        if (DateToday === new Date(data.adate).getDate()) {
            console.log("Match");
        
                    flag = true;
        
        }
    });

    useEffect(()=>{ 
        if (flag) {
            newShowBody(<><h1 style={{"color":"red"}}>Todays Day Allowance Aleardy Taken</h1></>);
        } 
    }, [flag]);



        return(<>{oldShowBody}</>);



    
 
    
}

export default DayAllowShow;