import { useEffect, useState } from "react";
import axios from "axios";
import { GetCreds,UserCreateAPI,UserDelAPI } from "../../ADAPI.js";
const UserMan = () => { 
    const [oldUserData, newUserData] = useState([]);
    const [oldUserName, newUserName] = useState();
    const [oldEmail, newEmail] = useState();
    const DataGetter = () =>{
        axios.get(GetCreds).then(response => 
            {
                if(response.data.Status === "fail"){
                    alert("Something Went Wrong");
                }else{
                    newUserData(response.data);
                }
            })
    }

    useEffect(DataGetter,[]);
    const HandleSubmit = (event) =>{
        event.preventDefault();
        if(Boolean(oldUserName) && Boolean(oldEmail)){
            const ADUserObj = {
                aname: oldUserName,
                aemail : oldEmail
            }
            axios.post(UserCreateAPI, ADUserObj).then(response => { 
                console.log(response.data);
                DataGetter();
                window.location.reload();
            })
        }
    }
    return(<div className="container">
        
    <form onSubmit={HandleSubmit}>
        <input type="text" onChange={(event)=>{ newUserName(event.target.value); }} className="form form-control" placeholder="Enter Users Name" />
        <input type="email" onChange={(event)=>{ newEmail(event.target.value); }} className="form form-control" placeholder="Enter Email ID" />
        <button type="submit" className="btn btn-primary">Submit</button>
    </form>
    <table>
        <thead>
            <th>SNO</th>
            <th>User Name</th>
            <th>Email ID</th>
            <th>Type</th>
            <th>Date</th>
            <th>Action</th>

        </thead>
        <tbody>
            {oldUserData.map(data=>{
                return <tr>
                <td data-column="Sno">{data.sno}</td>
                <td data-column="Name">{data.aname}</td>
                <td data-column="Email">{data.aemail}</td>
                <td data-column="Type">{data.atype}</td>
                <td data-column="Date">{data.adate}</td>
                <td data-column="Action"><button className="btn btn-danger" onClick={()=>{
                    axios.post(UserDelAPI, {sno:data.sno}).then(response => {
                        DataGetter();
                    })
                }}>Delete</button></td>


            </tr>
            })}
        </tbody>
    </table>
    </div>);
}

export default UserMan;