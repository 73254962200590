import CreditsAdd from  "../Admin/Components/CreditsAdd.js";
import "./Accounts.css";
import { useState } from "react";
const Accounts = () => { 
    const [UsersComponent, UpdateUsersComponent] = useState(<CreditsAdd />);

    return(<>
    <div className="s-layout">
  {/* Sidebar */}
  <div className="s-layout__sidebar">
    <a className="s-sidebar__trigger" >
      <i className="fa fa-bars" />
    </a>
    <nav className="s-sidebar__nav">
      <ul>
        <li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<CreditsAdd />); }} >
            <i className="fa fa-tasks" />
            <em>Credits Man</em>
          </a>
        </li>
        {/* <li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<ReportSwap />); }} >
            <i className="fa fa-file" />
            <em>Report Management</em>
          </a>
        </li> */}

{/* My Add */}








      </ul>
    </nav>
  </div>
  {/* Content */}
  {/* <main className="s-layout__content">
    <h1>Full View, Please!</h1>
  </main> */}
{/* <div className="container">
hello
</div> */}
  <main className="s-layout__content">
    {UsersComponent}
  </main>
</div>

    
    
    </>)
}

export default Accounts;