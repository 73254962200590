import { useState, useEffect } from "react";
import axios from "axios";
import { GetCreds,DailyReportGetter } from "../../ADAPI";
const ReportSwap = () => { 
    const [oldUserName, newUsername] = useState();
    const [oldUserEmail, newUserEmail] = useState(); //UserName
    const [oldCredData, newCredData] = useState([]);
    const [oldDReportData, newReportData] = useState([]);
    useEffect(()=>{axios.get(GetCreds).then(response => {
        if (response.data.Status === "fail") {
          
        }  else { 
              newCredData(response.data);
        }
      })},[])
    useEffect(()=> { 
        axios.post(DailyReportGetter, { aemail: oldUserEmail }).then(
            response => { newReportData(response.data); }
        )
    }, [oldUserEmail]);
    return(<div className="container">
    <label>Select User</label>
    <select className="form-select" onChange={(event)=>{ const [a,b] = event.target.value.split(','); newUserEmail(a); newUsername(b)}}>
            <option></option>
            {oldCredData.map(data=>{
                
                return  <option value={[data.aemail, data.aname]}> {data.aname} </option>
                        
            })}
           


            </select>
            <table>
        <thead>
          <tr>
            <th>S No</th>
            <th>Reported By</th>
            <th>Report</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
{
   oldDReportData.map(data => {
    return <tr>
    <td data-column="Sno">{data.sno}</td>
    <td data-column="Credited To">{data.aname}</td>
    <td data-column="Amount">{data.areport}</td>
    <td data-column="Date">{data.adate}</td>
  </tr>
   }).reverse()
}
</tbody>
      </table>



    </div>)
}

export  default ReportSwap;