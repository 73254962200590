import { useState } from "react";
import { useLocation } from "react-router-dom";
// import Attendance from "./Components/Attendance";
import DayAllow from "./Components/DayAllow";
import DayAllowShow from "./Components/DayAllowShow";
import Goods from "./Components/Goods";
import HomePage from "./Components/HomePage";
import Hotel from "./Components/Hotel";
import Reports from "./Components/Reports";
import ReportSwap from "./Components/ReportSwap";
import Travel from "./Components/Travel";
import "./UserPage.css";

const UserPage = () => { 
    const [UsersComponent, UpdateUsersComponent] = useState(<HomePage />);

    return(<>
    
    <div className="s-layout">
  {/* Sidebar */}
  <div className="s-layout__sidebar">
    <a className="s-sidebar__trigger" >
      <i className="fa fa-bars" />
    </a>
    <nav className="s-sidebar__nav">
      <ul>
        <li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<HomePage />); }} >
            <i className="fa fa-home" />
            <em>Home</em>
          </a>
        </li>
        <li>
          <a className="s-sidebar__nav-link" onClick={ ()=>{ UpdateUsersComponent(<Travel />); } } >
            <i className="fa fa-plane" />
            <em>Travel</em>
          </a>
        </li>
        <li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<Hotel />); }} >
            <i className="fa fa-cutlery" />
            <em>Hotels And Lodge</em>
          </a>
        </li>

{/* My Add */}

        <li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<Goods />); }} >
            <i className="fa fa-truck" />
            <em>Goods Purchase</em>
          </a>
        </li>

        <li>
          <a className="s-sidebar__nav-link" onClick={()=> { UpdateUsersComponent(<DayAllowShow />) }} >
            <i className="fa fa-money" />
            <em>Day Allowance</em>
          </a>
        </li>


        <li>
          <a className="s-sidebar__nav-link" onClick={()=> { UpdateUsersComponent(<Reports />) }} >
            <i className="fa fa-bar-chart" />
            <em>Tasks</em>
          </a>
        </li>

        <li>
          <a className="s-sidebar__nav-link" onClick={()=> { UpdateUsersComponent(<ReportSwap />) }} >
            <i className="fa fa-file" />
            <em>Reports</em>
          </a>
        </li>


        {/* <li>
          <a className="s-sidebar__nav-link" onClick={()=> { UpdateUsersComponent(<Attendance />) }} >
            <i className="fa fa-calendar" />
            <em>Attendence (Beta).</em>
          </a>
        </li> */}



      </ul>
    </nav>
  </div>
  {/* Content */}
  {/* <main className="s-layout__content">
    <h1>Full View, Please!</h1>
  </main> */}
{/* <div className="container">
hello
</div> */}
  <main className="s-layout__content">
    {UsersComponent}
  </main>
</div>

    
    
    </>);
}

export default UserPage;