import axios from "axios";
import { useEffect, useState } from "react";
import { OGetReturnCredits, ODelReturnCredis } from "../../ADAPI";

const ReturnCredsTab = () => { 
    const [oldCredData, newCredData] = useState([]);
    const GetterDataReturnCreds = () => { 
        axios.get(OGetReturnCredits).then(reposnes => { 
            newCredData(reposnes.data);
         })
    }
    useEffect(GetterDataReturnCreds, []);

    return(<>
   
 
    <table>
        <thead>
          <tr>
            <th>S No</th>
            <th>User Name</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {     oldCredData.map(data => {
                return <tr>
                <td data-column="Sno">{data.sno}</td>
                <td data-column="User name">{data.aname}</td>
                <td data-column="Amount">{data.acred}</td>
                <td data-column="Date">{data.adate}</td>
                <td data-column="Action"><button className="btn btn-danger"
                 onClick={() => { axios.post(ODelReturnCredis, { sno: data.sno }).then(
                    response => { GetterDataReturnCreds() }
                 ) }}
                 >Delete</button></td>
              </tr>
              }).reverse() }
          
        </tbody>
      </table>
    </>)
}

export default ReturnCredsTab;