import axios from "axios";
import { useState } from "react";
import FileBase64 from 'react-file-base64';
import { useLocation } from "react-router-dom";
import { PostTravelApi, DebApi } from "../../ADAPI";
import TravelTab from "./DataTables/TravelTab";
import { useEffect } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const Travel = () => { 
    const location = useLocation();
    const [oldForPercentage, newForPercentage] = useState(0);
    const [oldShowImgUp, newShowImgUp] = useState(); // For Changing travelling State
    const [oldImgData, newImgData] = useState(); //For Storing Img Data
    const [oldAmountInserted, newAmountInserted] = useState(); // For Amount Verification

    // Variable TO store Form Inputs
    const [oldFrom, newFrom] = useState(); // Initial Position
    const [oldTo, newTo] = useState(); // Dest Location
    const [oldTransMode, newTransMode] = useState(); // For Storing Transport 
    const [oldAmount, newAmount] = useState();
    const [oldSubmitData, newOnSubmitData] = useState();

    const [oldTab, newTab] = useState();
    const Reloadtab = () => {
        newTab(<TravelTab Swap="yes" />);
    }
    useEffect(Reloadtab,[]);
    const HandleTicketClick = () => { 
        newShowImgUp(<>
        {/* <FileBase64  multiple={false} onDone={(obj)=> { newImgData(obj.base64); }} /> */}
        <input type="file" accept=".png, .jpg, .jpeg" onChange={(event)=>{ newImgData(event.target.files[0]) }} name="aticket" />
       
        </>);
    }
    console.log(oldImgData);
    const HandleFormSubmit = (event) => { 
        event.preventDefault();
        
        


        if (oldTransMode === "Bus" || oldTransMode === "Train") {
            if(Boolean(oldFrom) && Boolean(oldTo) && Boolean(oldTransMode) && Boolean(oldAmount) && Boolean(oldImgData)) { 
                // const TravObj = { 
                    
                //         aname:location.state.username,
                //         aemail:location.state.emailid,
                //         aamount:oldAmount,
                //         afrom:oldFrom,
                //         ato:oldTo,
                //         aby:oldTransMode,
                //         aticket:oldImgData
                    
                // }
                const formdata = new FormData();
                formdata.append('aname', location.state.username);
                formdata.append('aemail', location.state.emailid);
                formdata.append('aamount', oldAmount);
                formdata.append('afrom', oldFrom);
                formdata.append('ato', oldTo);
                formdata.append('aby', oldTransMode);
                formdata.append('aticket', oldImgData);
    
    
    
                const DebObj = { 
                    aname :location.state.username,
                    aemail :location.state.emailid,
                    aamount :oldAmount,
                    apurp : oldTo,
                    atable:"Travel"
                }
                axios.post(DebApi, DebObj).then(response => { console.log(response.data); })
                axios.post(PostTravelApi, formdata, {headers: {
                    'content-type': 'multipart/form-data'
                }, onUploadProgress: (progressSwap) => { newForPercentage(Math.floor( (progressSwap.loaded*100)/progressSwap.total )) }}).then(response => {
                        console.log(response);
                        newOnSubmitData(<h6 style={{"color":"green"}}>Successfully Inserted</h6>);
                        Reloadtab();
                        newAmount("");
                        newFrom("");
                        newTo("");
                        newTransMode("");
                        newImgData("");
                 
    
                });
                event.target.reset();
            }else { 
                newOnSubmitData(<h6 style={{"color":"red"}}>All Fields Required</h6>);
            }
        } else { 
            if(Boolean(oldFrom) && Boolean(oldTo) && Boolean(oldTransMode) && Boolean(oldAmount)) { 
                // const TravObj = { 
                    
                //         aname:location.state.username,
                //         aemail:location.state.emailid,
                //         aamount:oldAmount,
                //         afrom:oldFrom,
                //         ato:oldTo,
                //         aby:oldTransMode,
                //         aticket:oldImgData
                    
                // }
                const formdata = new FormData();
                formdata.append('aname', location.state.username);
                formdata.append('aemail', location.state.emailid);
                formdata.append('aamount', oldAmount);
                formdata.append('afrom', oldFrom);
                formdata.append('ato', oldTo);
                formdata.append('aby', oldTransMode);
                formdata.append('aticket', oldImgData);
    
    
    
                const DebObj = { 
                    aname :location.state.username,
                    aemail :location.state.emailid,
                    aamount :oldAmount,
                    apurp : oldTo,
                    atable:"Travel"
                }
                axios.post(DebApi, DebObj).then(response => { console.log(response.data); })
                axios.post(PostTravelApi, formdata, {headers: {
                    'content-type': 'multipart/form-data'
                }, onUploadProgress: (progressSwap) => { newForPercentage(Math.floor( (progressSwap.loaded*100)/progressSwap.total )) }}).then(response => {
                        console.log(response);
                        newOnSubmitData(<h6 style={{"color":"green"}}>Successfully Inserted</h6>);
                        Reloadtab();
                        newAmount("");
                        newFrom("");
                        newTo("");
                        newTransMode("");
                        newImgData("");
                 
    
                });
                event.target.reset();
            }else { 
                newOnSubmitData(<h6 style={{"color":"red"}}>All Fields Required</h6>);
            }
        }
        
    }
    return(<div className="container">
    <h6 style={{"color":"red"}}>Instruction: 1. All Fields Required <br /> 2. Before Submit Verify the Fields</h6>
    <br />
    <form onSubmit={HandleFormSubmit} encType="multipart/form-data">
        <label>From : Initial Place</label>
        <input name="hello" className="form form-control" type="text" placeholder="From" onChange={(event)=>{
            newFrom(event.target.value);
        }} />

        <label>To : Destination</label>
        <input className="form form-control" type="text" placeholder="To" onChange={(event)=>{ newTo(event.target.value); }} />

        <label>Travelling Type</label><br />
        <input className="form-check-input" type="radio" name="ss" onClick={()=>{HandleTicketClick();newTransMode("Bus")}} /> Bus
        <input className="form-check-input" type="radio" name="ss" onClick={()=>{HandleTicketClick();newTransMode("Train")}} /> Train
        
        <input className="form-check-input" type="radio" name="ss" onClick={()=>{  newShowImgUp(<>
            <input type="text" className="form-control" placeholder="Travalling Vehicle" onChange={(event)=>{
                newTransMode(event.target.value);
            }} />
        </>) }}/>Other
        
        {oldShowImgUp}

        {/* <img src={oldImgData} alt="Upload Image Ticket Available" height="50px" width="50px" /> */}
        <br />
        <label>Amount</label>
        <input className="form form-control" type="text" placeholder="Amount" onChange={(event)=>{ if(isNaN(event.target.value)){ newAmountInserted(<h6 style={{"color":"red"}}>"Please Enter Valid Value"</h6>); } else
        { newAmountInserted(<>INR : {event.target.value} ₹ </>); newAmount(event.target.value); }
    }}/> {oldAmountInserted}
        <br />
        {oldSubmitData}

        <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar value={oldForPercentage} text={`${oldForPercentage}%`} />;
            </div>
        <button type="submit" className="btn btn-primary">Submit</button>
        <button type="reset" className="btn btn-danger">Clear</button>



    </form>
    {oldTab}
    </div>)
}

export default Travel;