import axios from "axios";
import { useEffect, useState } from "react";
import {GetCredDateSwap} from "../../../ADAPI";

const TwoDays = (props) => {
    const [oldData, newData] = useState([]);
    const DataGetter = () => {
        axios.get(GetCredDateSwap).then(response => { newData(response.data) })
    }
    
    useEffect(()=>{
        DataGetter();
    }, [props])

    return(<>
    
    <table>
        <thead>
          <tr>
            <th>S No</th>
            <th>UserName</th>
            <th>Amount</th>
            <th>Date</th>
          </tr>
        </thead>
    <tbody>
 { oldData.map( data => { 
    return     <tr>
    <td data-column="Sno">{data.sno}</td>

    <td data-column="Name">{data.aname}</td>
     <td data-column="Amount">{data.acred}</td>
      <td data-column="Date">{data.adate}</td>

   
  </tr>
  } ).reverse() }


  
          
        </tbody>
      </table>
    
    
    </>)
}

export default TwoDays;