import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DailyReportPoster,DailyReportGetter } from "../../ADAPI";
const ReportSwap = () => { 
    const [oldReport, newReport] = useState();
    const location = useLocation();
    const HandleSwapRepoSubmit = (event) => { 
        event.preventDefault();
        if(Boolean(oldReport)) { 
            const SwapObj = { 
                aname : location.state.username,
                aemail : location.state.emailid,
                areport : oldReport
            }
            axios.post(DailyReportPoster, SwapObj).then(repospone => { 
                SwapDataGetter();
                event.target.reset();
            })
        }
    }

    const [oldDReportData, newReportData] = useState([]);
    const SwapDataGetter = ()=> { 
        axios.post(DailyReportGetter, { aemail: location.state.emailid }).then(
            response => { newReportData(response.data); }
        )
    }
    useEffect(SwapDataGetter, [])
    return(<div className="container">
    <form onSubmit={HandleSwapRepoSubmit}>
        <textarea type="text" className="form form-control" onChange={ (event)=> { 
            newReport(event.target.value);
        } } />
        <button className="btn btn-info">Send Report</button>
    </form>
                <table>
        <thead>
          <tr>
            <th>S No</th>
            <th>Reported By</th>
            <th>Report</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
{
   oldDReportData.map(data => {
    return <tr>
    <td data-column="Sno">{data.sno}</td>
    <td data-column="Reported By">{data.aname}</td>
    <td data-column="Report">{data.areport}</td>
    <td data-column="Date">{data.adate}</td>
  </tr>
   }).reverse()
}
</tbody>
      </table>
    
    </div>);
}

export default ReportSwap;