import { useLocation } from "react-router-dom";
import logo from "./logo.png";
// import '@coreui/coreui/dist/css/coreui.min.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CChart } from "@coreui/react-chartjs";
import { useEffect, useState } from "react";
import axios from "axios";
import { CreditsMoney, TotalCredMoney, TravelMoney, HotelMoney, GoodsMoney, AllowMoney,OTravelMoney, OHotelMoney, OGoodsMoney, OAllowMoney, ReturnCreds, OReturnCreds, OTasks, OComps
,MTasks, MComps } from "../../ADAPI";
const HomePage = () => { 

  // Variable For Storing Count of Task
  const [oldOTasks, newOTasks] = useState(0);
  const [oldOComps, newOComps] = useState(0);
  const [oldMTasks, newMTasks] = useState(0);
  const [oldMComp, newMComp] = useState(0);

  // Variable For Storing Count of Task

    const [oldTotal, newTotal] = useState(0); // Total Credits
    const [oldmonthcred, newmonthcred] = useState(0); // Current Month Credits
    const [oldTrav, newTrav] = useState(0); // Cuurent Travalling Debs
    const [oldGoods, newGoods] = useState(0); // Current
    const [oldHotel, newHotel] = useState(0); // Current
    const [oldAllow, newAllow] = useState(0); // Current
    const [oldReturnCreds, newReturnCreds] = useState(0);
// Overall Year Calcs
    const [oldOTrav, newOTrav] = useState(0); // Cuurent Travalling Debs
    const [oldOGoods, newOGoods] = useState(0); // Current
    const [oldOHotel, newOHotel] = useState(0); // Current
    const [oldOAllow, newOAllow] = useState(0); // Current
    const [oldOReturnCreds, newOReturnCreds] = useState(0);

    const CalAll = () => { 
      axios.post(TotalCredMoney, { aemail : location.state.emailid }).then(response =>{
          response.data.map(data=>{ if (!isNaN(data.asum)) {
            newTotal(data.asum); 
          }
          })
      });
      axios.post(CreditsMoney, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{  if (data.asum === null) {} else {newmonthcred(data.asum)}      }); 
      });
      axios.post(TravelMoney, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) {} else { newTrav(data.asum)} }); 
      });
      axios.post(GoodsMoney, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) {}else{newGoods(data.asum)} }); 
      });
      axios.post(HotelMoney, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { }else{newHotel(data.asum)} }); 
      });
      axios.post(AllowMoney, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { } else{ newAllow(data.asum)} }); 
      });
      axios.post(ReturnCreds, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { } else{ newReturnCreds(data.asum)} }); 
      });

      axios.post(OTravelMoney, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) {} else { newOTrav(data.asum)} }); 
      });
      axios.post(OGoodsMoney, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) {}else{newOGoods(data.asum)} }); 
      });
      axios.post(OHotelMoney, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { }else{newOHotel(data.asum)} }); 
      });
      axios.post(OAllowMoney, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { } else{ newOAllow(data.asum)} }); 
      });
      axios.post(OReturnCreds, { aemail : location.state.emailid }).then(response => {
        response.data.map(data=>{ if (data.asum === null) { } else{ newOReturnCreds(data.asum)} }); 
      });


      // Overall Task Completion Ratio
      axios.post(OTasks, { aemail: location.state.emailid }).then(
        response => { response.data.map(data => { if (data.acount === null) {
          
        } else {
          newOTasks(data.acount);
        } }) }
      );

      axios.post(OComps, { aemail: location.state.emailid }).then(
        response => { response.data.map(data => { if (data.acount === null) {
          
        } else {
          newOComps(data.acount);
        } }) }
      );

      axios.post(MTasks, { aemail: location.state.emailid }).then(
        response => { response.data.map(data => { if (data.acount === null) {
          
        } else {
          newMTasks(data.acount);
        } }) }
      );
      axios.post(MComps, { aemail: location.state.emailid }).then(
        response => { response.data.map(data => { if (data.acount === null) {
          
        } else {
          newMComp(data.acount);
        } }) }
      );


      
    }
    useEffect(CalAll,[]);

    // console.log(oldOComps);
    // console.log(oldOTasks);
    // console.log(oldMComp);
    // console.log(oldMTasks);

    // console.log(oldAllow);
    // console.log(oldGoods);
    // console.log(oldHotel);
    // console.log(oldTrav);
    
    // console.log(oldReturnCreds);
    // console.log(oldOReturnCreds);

    const Swap = parseFloat(oldAllow)+parseFloat(oldGoods)+parseFloat(oldHotel)+parseFloat(oldTrav); // Total Monthly uses
    const Swap2 = parseFloat(oldOAllow)+parseFloat(oldOGoods)+parseFloat(oldOHotel)+parseFloat(oldOTrav); 
    // Yearly Uses
    const YearlyRemainingCreds =  oldTotal - Swap2 - oldOReturnCreds;

    const MonthRemainingCreds = oldmonthcred-Swap - oldReturnCreds;





    const location = useLocation();
    // const notify = () => toast(<><button >Click Me</button></>);
    return(<div className="container text-center">
       
    <img src={logo} height="100px" className="img-fluid" width="100px" alt="AD Logo" />
    
    <br />
    <hr />
    <label style={{"float":"right"}}>{location.state.username}</label>
    <CChart
  type="doughnut"
  data={{
    // labels: ['Overall Remaining Credits', 'This Month Credits', 'Current Month Use', 'This Month Remaining Credits', 'This Month Return Credits' ],
    labels: ['Overall Remaining Credits', 'This Month Credits', 'Current Month Use'],
    datasets: [
      // {
      //   backgroundColor: ['green', 'blue', 'red', 'yellow','skyblue'],
      //   data: [YearlyRemainingCreds, oldmonthcred, Swap, MonthRemainingCreds, oldReturnCreds],
      // },
            {
        backgroundColor: ['green', 'blue', 'red'],
        data: [YearlyRemainingCreds, oldmonthcred, Swap],
      },
    ],
  }}
  style={{"height":"100%", "width":"100%"}}
/>

{/* Pie Chart */}


<CChart 
type="bar"

data = {{
  labels:['Overall Assign Tasks', "Overall Completion", "Current Month Assign Tasks", "Current Month Completion"],
  datasets: [{
    borderWidth: 5,
    data: [oldOTasks, oldOComps, oldMTasks, oldMComp],
    label:"Tasks History",
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ],
    borderWidth: 1
  }]
}}

style={{"height":"100%", "width":"100%"}}
/>





{/* <button onClick={notify}>Notify!</button>
<ToastContainer autoClose={false} /> */}
    


    
    </div>);
}

export default HomePage;