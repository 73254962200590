import { Link } from "react-router-dom";


const App = () => {
  return (
    <><div className="container d-flex justify-content-center"> <h1>Nothing Here 404 Error</h1>
        
       </div><br />
       <hr />
       <a href="https://adigrp.com/" className="btn btn-info d-flex justify-content-center"> Visit Home Page</a>
     
       </>
  );
}

export default App;
