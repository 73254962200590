import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminPage from './Admin/AdminPage';
import UserPage from './Users/UserPage';
import AdminLog from './AdminLog';
import SubAdmin from './SubAdmin/SubAdmin';
import Accounts from './Accounts/Accounts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/admin' element={<AdminLog />} />
      <Route path='/admin/adminpage' element={<AdminPage />} />
      <Route path='/admin/userpage' element={<UserPage />} />
      <Route path='/admin/subadmin' element={<SubAdmin />} />
      <Route path='/admin/accpage' element={<Accounts />} />

      <Route path='*' element={<>Nothing Here ! Code 404 Error</>} />

    </Routes>
  </BrowserRouter>
  
);


