import axios from "axios";
import { useEffect, useState } from "react";
import FileBase64 from 'react-file-base64';
import { useLocation } from "react-router-dom";
import { PostLoggHotel, DebApi } from "../../ADAPI";
import HotelTab from "./DataTables/HotelTab";
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const Hotel = () => { 
    const notify = (sno) => toast(<><button >Click Me {sno}</button></>);
    const location = useLocation();
    const [oldForPercentage, newForPercentage] = useState(0);
    const [oldHotelName, newHotelName] = useState(); // Hotel Name Store
    const [oldPlace, newPlace] = useState(); // Location store
    const [oldAmountInserted, newAmountInserted] = useState(); // For Amount Verification 
    const [oldAmount, newAmount] = useState(); // Amount Store
    const [oldImgData, newImgData] = useState(); //For Storing Img Data
    const [oldSubmitData, newOnSubmitData] = useState();
    const [oldTab, newTab] = useState();
    const Reloadtab = () => {
        newTab(<HotelTab RefreSwap="yes" />);
    }
    useEffect(Reloadtab,[]);
    const HandleFormHotel= (event) => {
        event.preventDefault();
        if(Boolean(oldAmount) && Boolean(oldHotelName) && Boolean(oldPlace) && Boolean(oldImgData)){
            // const HotelObj = {
            //     aname : location.state.username,
            //     aemail: location.state.emailid,
            //     aamount : oldAmount,
            //     ahotel : oldHotelName,
            //     aplace : oldPlace,
            //     abill : oldImgData
            // }

            const formdata = new FormData();
            formdata.append('aname', location.state.username);
            formdata.append('aemail', location.state.emailid);
            formdata.append('aamount', oldAmount);
            formdata.append('ahotel', oldHotelName);
            formdata.append('aplace', oldPlace);
            formdata.append('abill', oldImgData);
            const DebObj = { 
                aname :location.state.username,
                aemail :location.state.emailid,
                aamount :oldAmount,
                apurp : oldHotelName,
                atable:"hotel"
            }
            axios.post(DebApi, DebObj).then(response => { console.log(response.data); })
            axios.post(PostLoggHotel, formdata, {headers: {
                'content-type': 'multipart/form-data'
            }, onUploadProgress: (progressSwap) => { newForPercentage(Math.floor( (progressSwap.loaded*100)/progressSwap.total )) }}).then(response => { 
                console.log(response);
                if (response.data.Status  === "done") {
                    newOnSubmitData(<h6 style={{"color":"green"}}>Successfully Inserted</h6>);
                    Reloadtab();
                    newAmount("");
                    newHotelName("");
                    newPlace("");
                    newImgData("");
                }
             });
             event.target.reset();
        }else { 
            newOnSubmitData(<h6 style={{"color":"red"}}>All Fields Required</h6>);
        }
    }
    

   
console.log(oldForPercentage);
    return(<div className="container">
        <form onSubmit={HandleFormHotel} encType="multipart/form-data">
            <label className="label">Hotel/Lodge Name</label>
            <input type="text" className="form form-control" onChange={(event)=>{ newHotelName(event.target.value); }}  placeholder="Hotel/Logg Name" />
            <label className="label">Hotel/Lodge Place</label>
            <input type="text" className="form form-control" onChange={(event)=>{ newPlace(event.target.value); }}  placeholder="Hotel/Logg Place" />
            
            <label>Enter Bill Amount</label>
            <input className="form form-control" type="text" placeholder="Amount" onChange={(event)=>{ if(isNaN(event.target.value)){ newAmountInserted(<h6 style={{"color":"red"}}>"Please Enter Valid Value"</h6>); } else
            { newAmountInserted(<>INR : {event.target.value} ₹ </>); newAmount(event.target.value); }
            }}/> {oldAmountInserted}
<br />
            <label className="label">Upload Image Below</label>
            {/* <FileBase64 multiple={false} onDone={(obj)=> { newImgData(obj.base64); }} /> */}
            <input type="file" accept=".png, .jpg, .jpeg" onChange={(event)=>{ newImgData(event.target.files[0]) }} name="abill" />
            {/* <img src={oldImgData} alt="Upload Ticket Available" height="50px" width="50px" />       */}
            {oldSubmitData}
            <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar value={oldForPercentage} text={`${oldForPercentage}%`} />;
            </div>
            
            <br/>
            <button type="submit" className="btn btn-primary">Submit</button>
            <button type="reset" className="btn btn-danger">Clear</button>

        </form>
        <br />
        {oldTab}
        
    </div>);
}


export default Hotel;