import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {UserTaskRepo,AddingReport, TaskReportMan} from "../../ADAPI";

const Reports = () => { 
    const location = useLocation();
   
    const [oldDataRepoAndTask, newDataRepoAndTask] = useState([]);
    const GettTableData = () =>{ axios.post(TaskReportMan, { aemail : location.state.emailid }).then(response => {
        newDataRepoAndTask(response.data);
      }) }
    useEffect(GettTableData,[]);
    const [oldTaskFetchedData, newFetchedData] = useState([]);
    useEffect(()=>{
        axios.post(UserTaskRepo, { aemail: location.state.emailid }).then(response => { 
            console.log(response);
            newFetchedData(response.data);
        });
    },[])
    const [oldReportData, newReportData] = useState();


    const HandleSwapClick = (sno) => { 
        if(Boolean(oldReportData)) { 
            axios.post(AddingReport, { sno: sno, areport: oldReportData }).then(
                response=> {console.log(response);
                    
                    GettTableData();
                }
                
            )
               
            
        }
    }




    return(<div className="container">
        <p><code>e.g 1.Report of First Task<br />
                    &nbsp;&nbsp;&nbsp; 2. Report of Second Task.<br />
                    &nbsp;&nbsp;&nbsp;
                    3. Report of Third Task
        </code></p>

        {oldTaskFetchedData.map(data => {
                     return   <> 
                        Your Allocated Task
                        <p className="form-control"><code>
                            Tasks:<br />
                        {data.atask}
            
                        </code></p>

                            <textarea className="form form-control" onChange={(event)=>{ newReportData(event.target.value); }} defaultValue={data.areport}></textarea>
                           
                            <button className="btn btn-info" onClick={()=>{ HandleSwapClick(data.sno); }}>Update</button>
                            <hr />
                        </>
        })}

        
<table>
        <thead>
          <tr>
            <th>S No</th>
            <th>User Name</th>
            <th>Task</th>
            <th>Report</th>
            <th>Reporting Time</th>
            <th>Date of Allocation</th>
            

          </tr>
        </thead>
        <tbody>
            {oldDataRepoAndTask.map(data => { 
                return <tr>
                <td data-column="Sno">{data.sno}</td>
                <td data-column="User Name">{data.aname}</td>
                <td data-column="Task">{data.atask}</td>
                <td data-column="Report">{data.areport}</td>
                <td data-column="Task">{data.areportdate}</td>
                <td data-column="Task">{data.adate}</td>
             



              </tr>
             })}
          
        </tbody>
      </table>

    </div>);
}

export default Reports;