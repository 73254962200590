import CreditsAdd from "./Components/CreditsAdd";
import UserMan from "./Components/UserMan";
import "./AdminPage.css";
import { useState } from "react";
import ReturnAmount from "./Components/ReturnAmount";
import TaskMan from "./Components/TaskMan";
import ReportSwap from "./Components/ReportsSwap";

const AdminPage = () => { 
    const [UsersComponent, UpdateUsersComponent] = useState(<UserMan />);

    return(<>
    
    <div className="s-layout">
  {/* Sidebar */}
  <div className="s-layout__sidebar">
    <a className="s-sidebar__trigger" >
      <i className="fa fa-bars" />
    </a>
    <nav className="s-sidebar__nav">
      <ul>
        <li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<UserMan />); }} >
            <i className="fa fa-home" />
            <em>Users Management</em>
          </a>
        </li>

        <li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<CreditsAdd />); }} >
            <i className="fa fa-credit-card" />
            <em>Credit Amount And Reciept Generater</em>
          </a>
        </li>

{/* My Add */}

<li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<ReturnAmount />); }} >
            <i className="fa fa-retweet" />
            <em>Return Amount</em>
          </a>
        </li>

        <li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<TaskMan />); }} >
            <i className="fa fa-tasks" />
            <em>Tasks</em>
          </a>
        </li>
        <li>
          <a className="s-sidebar__nav-link" onClick={()=>{ UpdateUsersComponent(<ReportSwap />); }} >
            <i className="fa fa-file" />
            <em>Reports</em>
          </a>
        </li>

      </ul>
    </nav>
  </div>
  {/* Content */}
  {/* <main className="s-layout__content">
    <h1>Full View, Please!</h1>
  </main> */}
{/* <div className="container">
hello
</div> */}
  <main className="s-layout__content">
    {UsersComponent}
  </main>
</div>

    
    
    </>);
}

export default AdminPage;