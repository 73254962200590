import axios from "axios";
import { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DailyDataAPI, DebApi } from "../../ADAPI";
import DayAllowTab from "./DataTables/DayAllowTab";
const DayAllow = () => {
    const location = useLocation();
    const [oldAmount, newAmount] = useState();
    const [oldSubmitData, newOnSubmitData] = useState();
    const [oldTab, newTab] = useState();
    const Reloadtab = () => {
        newTab(<DayAllowTab RefreSwap="yes" />);
    }
    useEffect(Reloadtab,[]);
    const [oldShowMess, newShowMess]= useState(<><button className="btn btn-primary" type="submit">Submit</button></>);

    const HandleAllowSub =(event) => 
    {
        newShowMess(<>Please wait day allowance is being allocated.</>)
        event.preventDefault();
        if(Boolean(oldAmount)){
            const DayAllo = {
                aname:location.state.username,
                aemail:location.state.emailid,
                adayall:"Day Allowance",
                aamount:oldAmount
            }
            const DebObj = { 
                aname :location.state.username,
                aemail :location.state.emailid,
                aamount :oldAmount,
                apurp : "Day Allowance",
                atable:"Day Allowance"
            }
            
            axios.post(DebApi, DebObj).then(response => { console.log(response.data); });
            axios.post(DailyDataAPI, DayAllo).then(response => { 
                if (response.data.Status  === "done") {
                   newOnSubmitData("Done");
                   Reloadtab();
                   window.location.reload();
                }
             });
             
        }else {
            newOnSubmitData(<h6 style={{"color":"red"}}>Select Field Please</h6>);
        }
    }
    return(<div className="container">
        <form onSubmit={HandleAllowSub}>
        <label>Amount</label><br />
        <input type="radio" value="250" name="da" className="form-check-input" onClick={(event)=>{
            newAmount(event.target.value);
        }} /> 250
        
        <input type="radio" value="350" name="da" className="form-check-input" onClick={(event)=>{
            newAmount(event.target.value);
        }} /> 350
        <br />
        {oldSubmitData}<br/>
    {/* <button className="btn btn-primary" type="submit">Submit</button> */}
    {oldShowMess}  
    </form>
        <br />
        {oldTab}
    </div>);
}

export default DayAllow;